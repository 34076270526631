import axios from 'axios'
import { defaultMatches } from '../data/defaultData'

export default {
  namespaced: true,
  state: {
    userId: 0,
    list: [],
    leagues: [],
    betting_matches: [],  // additional state for betting matches
    currentMatch: null,
    currentOdds: null
  },
  mutations: {
    SET_USERID(state, id) {
      state.userId = id
    },
    SET_MATCHES(state, matches) {
      state.list = matches
    },
    SET_BETTING_MATCHES(state, betting_matches) {
      state.betting_matches = betting_matches
    },
    SET_LEAGUES(state, leagues) {
      state.leagues = leagues
    },
    SET_CURRENT_MATCH(state, match) {
      state.currentMatch = match
    },
    SET_CURRENT_ODDS(state, odds) {
      state.currentOdds = odds
    }
  },
  actions: {
    async fetchMatches({ commit }) {
      try {
        // const response = await axios.get('YOUR_API_URL/matches')
        const user_id = localStorage.getItem('userId')
        // const response = await axios.get('https://api.ip008.com/odds/matches.php')
        const response = await axios.get('https://api.ip008.com/odds/matches2.php?user_id='+user_id)
        commit('SET_MATCHES', response.data.matches)
        commit('SET_BETTING_MATCHES', response.data.betting_matches)
        commit('SET_LEAGUES', response.data.leagues)
      } catch (error) {
        console.error('Error fetching matches:', error)
        commit('SET_MATCHES', defaultMatches)
      }
    }
  },
  getters: {
    getMatchById: (state) => (id) => {
      return state.list.find(match => match.match_id === id)
    }
  }
}