const SETTINGS_KEY = 'app_settings'

const state = {
  history_days: localStorage.getItem(SETTINGS_KEY) 
    ? JSON.parse(localStorage.getItem(SETTINGS_KEY)).history_days 
    : 7
}

const mutations = {
  SET_HISTORY_DAYS(state, days) {
    state.history_days = days
    const settings = JSON.parse(localStorage.getItem(SETTINGS_KEY)) || {}
    settings.history_days = days
    localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings))
  }
}

const actions = {
  updateHistoryDays({ commit }, days) {
    commit('SET_HISTORY_DAYS', days)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
