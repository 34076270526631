<template>
  <div>
    <Header />
    <div class="settings">
      <div class="setting-item">
        <span>显示最近</span>
        <a-select
          v-model:value="selectedDays"
          style="width: 80px; margin: 0 8px"
        >
          <a-select-option v-for="day in daysOptions" :key="day" :value="day">
            {{ day }}天
          </a-select-option>
        </a-select>
        <span>的投注记录</span>
      </div>
      
      <a-button id="logout" type="primary" @click="logout">退出</a-button>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    Header
  },
  data() {
    return {
      daysOptions: [3, 7, 10, 15, 30],
      selectedDays: this.$store.state.settings.history_days
    }
  },
  computed: {
    ...mapState('settings', ['history_days'])
  },
  watch: {
    selectedDays(newVal) {
      this.updateHistoryDays(newVal)
    }
  },
  methods: {
    ...mapActions('settings', ['updateHistoryDays']),
    logout() {
      // 清除登录状态
      localStorage.removeItem('isLoggedIn');
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>
.settings {
  padding: 20px;
}
#logout {
  margin-top: 20px;
}
</style>
