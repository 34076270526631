import axios from 'axios'
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    userId: 0,
    users: [],
    matches: [],
    bettings: [],
    currentMatch: null,
    currentOdds: null
  },
  mutations: {
    SET_USERID(state, id) {
      state.userId = id
    },
    SET_BETTINGS(state, bettings) {
      console.log('SET_BETTINGS', bettings)
      Vue.set(state, 'users', bettings.users)
      Vue.set(state, 'matches', bettings.matches)
      Vue.set(state, 'match_bettings', bettings.match_bettings)
    },
    SET_CURRENT_MATCH(state, match) {
      state.currentMatch = match
    },
    SET_CURRENT_ODDS(state, odds) {
      state.currentOdds = odds
    }
  },
  actions: {
    async fetchBettings({ commit, rootState }, show_user_id) {
      try {
        const user_id = localStorage.getItem('userId')
        const history_days = rootState.settings.history_days
        const params = {
          user_id: user_id,
          history_days: history_days
        }
        
        if (user_id === '1') {
          params.show_user_id = show_user_id
        }

        const response = await axios.get('https://api.ip008.com/odds/bettings2.php', {
          params: params
        })
        
        commit('SET_BETTINGS', response.data)
        console.log('state after setting', this.state)
      } catch (error) {
        console.error('Error fetching bettings:', error)
      }
    }
  },
  getters: {
    getMatchById: (state) => (id) => {
      return state.matches.find(match => match.match_id === id)
    }
  }
}
