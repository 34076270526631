import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import MyOdds from '../views/MyOdds.vue'
import Settings from '../views/Settings.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true, title: '体彩助手 - 投注' }
  },
  {
    path: '/MyOdds',
    name: 'MyOdds',
    component: MyOdds,
    meta: { requiresAuth: true, title: '体彩助手 - 我的投注' }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { requiresAuth: true, title: '体彩助手 - 设置' }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem('isLoggedIn')
  
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.path === '/' && isLoggedIn) {
    next('/home')
  } else if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
    next('/')
  } else {
    next()
  }
})

export default router
