<template>
  <div class="home-container">
    <Header /> <!-- 引入顶部导航菜单组件 -->

    <div v-if="userId == 1">
      <a-select
        v-model:value="selectedUserId"
        @change="handleUserChange"
        placeholder="选择用户"
        style="width: 100%; max-width: 400px"
      >
        <a-select-option value="">选择用户</a-select-option>
        <a-select-option 
          v-for="user in users" 
          :key="user.user_id" 
          :value="user.user_id"
        >
          {{ user.phone }}
        </a-select-option>
      </a-select>
    </div>
    <div class="match-selection">
      <a-select
        v-model:value="selectedMatchId"
        @change="handleMatchChange"
        placeholder="选择比赛"
        style="width: 100%; max-width: 400px"
      >
        <a-select-option value="">选择比赛</a-select-option>
        <a-select-option 
          v-for="betting in match_bettings" 
          :key="betting.match_id" 
          :value="betting.match_id"
        >
          {{ betting.match_title }}
        </a-select-option>
      </a-select>
      <a-select
        v-model:value="selectedBettingId"
        @change="handleBettingChange"
        placeholder="选择投注"
        style="width: 100%; max-width: 400px"
        v-if="currentMatchBettings.length > 1"
      >
        <a-select-option value="">选择投注</a-select-option>
        <a-select-option 
          v-for="(betting, index) in currentMatchBettings" 
          :key="betting.betting_id" 
          :value="betting.betting_id"
        >
          第{{ index + 1 }}次
        </a-select-option>
      </a-select>
      <span v-if="userId == 1 && selectedBettingId">
        <a href="#" @click.prevent="deleteBetting">删除</a>
      </span>

      <!-- <select v-model="selectedOddsTime" @change="handleOddsChange">
        <option v-for="(table, index) in currentMatchOddsTables" 
                :key="index" 
                :value="table.publishTime">
          {{ table.publishTime }}
        </option>
      </select> -->
    </div>

    <!-- <div class="odds-list" v-if="currentOdds">
      <div v-for="(value, key) in currentOdds.odds" :key="key" class="odds-item">
        <label>
          <input type="checkbox" 
                 v-model="selectedOddsItems" 
                 :value="{ type: key, odds: value }">
          {{ key }} ({{ value }})
        </label>
      </div>
    </div> -->
    <div v-if="currentOdds && showOddsTable">
      <div>
        <check-table 
          :data="tableDataSet"
          :checkItems="selectedOddsItems"
          @table-selection-change="handleTableSelectionChange"
        />
      </div>
    </div>

    <div class="betting-table-section" v-if="selectedOddsItems.length">
      <div class="title-row">
        <h3>投注表 ({{ currentMatch.homeTeam }} vs {{ currentMatch.awayTeam }})</h3>
        <div style="display: flex; flex-direction: column; justify-content: flex-end;">
          <a href="#" @click.prevent="toggleTable">{{ linkText }}</a>
        </div>
      </div>
      <table class="betting-table" v-if="selectedOddsItems.length">
        <thead>
          <tr>
            <th>比分</th>
            <th>赔率</th>
            <th>投注</th>
            <th>收益</th>
            <th>收益减成本</th>
            <th>收益率</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in selectedOddsItems" :key="index">
            <td>{{ item.title }}</td>
            <td>{{ item.value }}</td>
            <td>{{ item.amount }}</td>
            <td :class="calculateClass(item.name)">{{ calculateReturn(item.value, item.amount) }}</td>
            <td>{{ calculateNetReturn(item.value, item.amount) }}</td>
            <td>{{ calculateROI(item.value, item.amount) }}%</td>
          </tr>
          <tr class="total-row">
            <td colspan="2">总计</td>
            <td>{{ totalBetting }}</td>
            <td v-if="!currentMatchScore" colspan="3"></td>
            <td v-if="currentMatchScore">比赛结果</td>
            <td v-if="currentMatchScore" colspan="2">{{ currentMatchScore }}</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import CheckboxTable from '../components/CheckboxTable.vue'
import CheckTable from '../components/CheckTable.vue';
import Header from '../components/Header.vue';

export default {
  name: 'MyOdds',
  meta: {
    title: '足彩助手 - 我的投注'
  },
  components: {
    Header,
    CheckTable,
    // 'a-button': Button,
    CheckboxTable
  },
  data() {
    return {
      selectedUserId: '',
      selectedMatchId: '',
      selectedBettingId: '',
      selectedOddsTime: '',
      selectedOddsItems0: [],
      bettingAmounts: [],
      showOddsTable: false,
      linkText: '显示赔率表',
      oddNames: {'had':'胜负', 'crsh':'主队胜', 'crsd':'主队平', 'crsa':'主队负', 'ttg':'总进球数', 'hafu':'半全场胜平负'}
    }
  },
  computed: {
    userId() {
      return localStorage.getItem('userId')
    },
    users() {
      return this.$store.state.bettings.users
    },
    all_matches() {
      return this.$store.state.bettings.matches
    },
    stat() {
      return this.$store.state.bettings
    },
    match_bettings() {
      // return this.$store.state.bettings.bettings
      return this.$store.state.bettings.match_bettings
    },
    currentBetting() {
      return this.currentMatchBettings?.find(b => b.betting_id === this.selectedBettingId) || null
    },
    currentMatches() {
      return this.currentBetting ? this.all_matches.filter(m => m.match_id === this.currentBetting.match_id) : []
    },
    currentMatch() {
      return this.$store.getters['bettings/getMatchById'](this.selectedMatchId)
    },
    currentMatchBettings() {
      const match = this.match_bettings?.find(m => m.match_id === this.selectedMatchId) || null
      return match?.match_bettings || []
    },
    currentMatchScore() {
      if (this.currentMatch && this.currentMatch.crsResult) {
        return '半场' + this.currentMatch.halfScore + ' 全场' + this.currentMatch.finalScore
      }
      return ''
    },
    currentMatchOddsTables() {
      return this.currentMatch?.oddsTables || []
    },
    currentOdds() {
      // const betting = this.current_betting?
      if (this.currentMatchOddsTables.length > 0) {
        return this.currentMatchOddsTables[this.currentMatchOddsTables.length - 1]
      }
      return null
    },
    totalBetting() {
      let total = 0
      console.log("totalBetting: ", this.selectedOddsItems)
      this.selectedOddsItems?.forEach(amount => {
        total += parseInt(amount.amount)
      });
      return total
      // return this.bettingAmounts.reduce((sum, amount) => sum + (amount || 0), 0)
    },
    selectedOddsItems() {
      return this.currentBetting?.amounts || []
    },
    tableDataSet() {
      if (this.currentOdds) {
        return [{"colCount": 3, "odds": this.currentOdds.had, "title": "胜负", "titleColor": "lightgrey"},
                {"colCount":8, "odds": this.currentOdds.crsh, "title": "主队胜", "titleColor": "lightblue"},
                {"colCount":6, "odds": this.currentOdds.crsd, "title": "平", "titleColor": "lightblue"},
                {"colCount":8, "odds": this.currentOdds.crsa, "title": "主队负", "titleColor": "lightblue"},
                {"colCount":4, "odds": this.currentOdds.ttg, "title": "总进球数", "titleColor": "lightgreen"},
                {"colCount":3, "odds": this.currentOdds.hafu, "title": "半全场胜平负", "titleColor": "lightpink"},
              ]
      }
      return []
    }
  },
  methods: {
    async fetchInitialData() {
      console.log("Fetching initial data for MyOdds. userId: ", this.userId)
      this.selectedUserId = this.userId;
      await this.$store.dispatch('bettings/fetchBettings', this.userId)
    },
    handleUserChange() {
      console.log("User changed", this.selectedUserId)
      this.selectedMatchId = ''
      this.$store.dispatch('bettings/fetchBettings', this.selectedUserId)
    },
    handleMatchChange() {
      if (this.currentMatchBettings) {
        this.selectedBettingId = this.currentMatchBettings[this.currentMatchBettings.length - 1].betting_id
      }
      // console.log("currentMatch", this.currentMatch, "currentMatchOddsTables", this.currentMatchOddsTables)
      // if (this.currentMatch && this.currentMatchOddsTables.length) {
      //   this.selectedOddsTime = this.currentMatchOddsTables[this.currentMatchOddsTables.length - 1].publishTime
      //   this.bettingAmounts = []
      // }
    },
    handleBettingChange() {
      if (this.currentBetting && this.currentMatches.length) {
        this.selectedMatchId = this.currentMatches[this.currentMatches.length - 1].match_id
        // this.selectedOddsTime = this.currentMatchOddsTables[0].publishTime
        this.bettingAmounts = []
        this.handleMatchChange()
      }
      // this.selectedOddsTime = ''
      console.log("MyOdds handleBettingChange", this.selectedBettingId)
    },
    handleOddsChange() {
      this.bettingAmounts = []
    },
    applyRecommendation1() {
      if (this.currentOdds) {
        const firstKey = Object.keys(this.currentOdds.odds)[0]
        this.selectedOddsItems0 = [{
          type: firstKey,
          odds: this.currentOdds.odds[firstKey]
        }]
        this.bettingAmounts = [0]
      }
    },
    applyRecommendation2() {
      if (this.currentOdds) {
        const secondKey = Object.keys(this.currentOdds.odds)[1]
        this.selectedOddsItems0 = [{
          type: secondKey,
          odds: this.currentOdds.odds[secondKey]
        }]
        this.bettingAmounts = [0]
      }
    },
    handleTableData(data) {
      // this.selectedData1 = data;
      // this.selectedOddsItems = data;
      console.log(data)
    },
    handleTableSelectionChange(items) {
      // this.selectedOddsItems = items;
      console.log("handleTableSelectionChange", items)
    },
    calculateReturn(odds, amount) {
      return ((odds * amount) || 0).toFixed(2)
    },
    calculateNetReturn(odds, amount) {
      return (((odds * amount) - this.totalBetting) || 0).toFixed(2)
    },
    calculateROI(odds, amount) {
      if (!amount) return '0.00'
      return (((odds * amount - this.totalBetting) / this.totalBetting) * 100).toFixed(2)
    },
    calculateResults() {
      this.bettingAmounts = [...this.bettingAmounts]
    },
    calculateClass(name) {
      if (this.currentMatch) {
        if (name == this.currentMatch.ttgResult
          || name == this.currentMatch.hafuResult
          || name == this.currentMatch.crsResult
        ) {
          return 'good-item';
        }
      }
      return '';
    },
    toggleTable() {
      this.showOddsTable =!this.showOddsTable;
      this.linkText = this.showOddsTable? '隐藏赔率表' : '显示赔率表';
    },
    async deleteBetting() {
      if (this.selectedBettingId > 0) {
        await axios.get('https://api.ip008.com/odds/bettings2.php?a=del&betting_id='+this.selectedBettingId)
        await this.$store.dispatch('bettings/fetchBettings', this.selectedUserId)
        handleMatchChange()
      }
    },
    logout() {
      localStorage.removeItem('isLoggedIn')
      this.$router.push('/')
    }
  },
  created() {
    this.fetchInitialData()
  }
}
</script>

<style scoped>
.home-container {
  padding: 0;
  max-width: 1200px;
  margin: 0 auto;
}

.logout-section {
  text-align: right;
  margin-bottom: 20px;
}

.logout-link {
  color: #666;
  text-decoration: none;
}

.match-selection {
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
}

select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  min-width: 200px;
}

.odds-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  margin-bottom: 20px;
}

.odds-item {
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.recommendation-buttons {
  margin-bottom: 20px;
}

.betting-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.betting-table th,
.betting-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.betting-table th {
  background-color: #f5f5f5;
}

.betting-table input {
  width: 100px;
  padding: 5px;
  text-align: right;
}

.total-row {
  background-color: #f9f9f9;
  font-weight: bold;
}

.betting-table-section h3 {
  font-weight: bold;
  margin-top: 10px;
}

.save-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #45a049;
}
.good-item {
  color: red;
}
.title-row {
  display: flex;
  justify-content: space-between;
}
</style>
